import { Button, Modal } from "react-bootstrap";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Form from "./Form/Form";
import React, { useEffect } from "react";
import PurchaseWithoutRegisteringSmallScreen from "./SmallScreen";
import { GlobalContext, GlobalContextType } from "../../context/GlobalContext";
import { toast } from "react-toastify";
import ApiSessionInstance from "../../helpers/session_api";
import Layout from "../../Layout";

interface IPurchaseWithoutRegisteringProps {
  children?: React.ReactNode; // For reusability on buy page
}

const PurchaseWithoutRegistering = (
  props: IPurchaseWithoutRegisteringProps
) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: "/continue-without-registration" }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isLargeScreenBelow = useMediaQuery({
    query: "(min-width: 992px)",
  });

  const { shoppingCartBasket } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  useEffect(() => {
    const mounted = ApiSessionInstance.getPurchaseWithoutRegisteringMounted();
    if (!mounted && !shoppingCartBasket.length) {
      ApiSessionInstance.onPurchaseWithoutRegisteringMounted();
      toast.error("Please add an item to your cart before proceeding");
      navigate("/shopping-cart");
    }
  }, [navigate, shoppingCartBasket]);

  const onHideHandler = () => {
    navigate("/");
  };

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="backgroundDropdown px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          onHide={onHideHandler}
          show={!!matches}
          size="xl"
          centered
          style={{ marginTop: "26px"}}
        >
          <h1 className="text-capitalize text-primary text-center" >
            {/* Shopping cart */}  BUY YOUR TICKET
          </h1>
          <Button
            onClick={() => navigate("/shopping-cart")}
            variant="white"
            className="text-white position-absolute end-0 mx-3"
            style={{marginTop: "-20px"}}
          >
            <i className="bi bi-x display-3" />
          </Button>
          <Form {...props} />
        </Modal>
      ) : (
        <PurchaseWithoutRegisteringSmallScreen {...props} />
      )}
    </Layout>
  );
};

export default PurchaseWithoutRegistering;
