import { Col } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Heading = () => {
  const isSmallDevice= useMediaQuery({
    query: "(max-width: 380px)"
  })

  return(
  <Col md={12} lg={6}>
    <div className="d-none d-md-none d-lg-block w-100 m-0">
      <div className="text-center">
        <h1
          className="text-primary"
          style={{
            letterSpacing: "5px",
            fontSize: "45px",
            fontWeight: "400",
            textShadow: "black 2px 2px 5px",
          }}
        >
          Welcome To Your <br />
          Ticketing Platform.
        </h1>

        <h2
          className="text-white mt-5 mb-4"
          style={{
            fontSize: "35px",
            fontWeight: "800",
            textShadow: "black 2px 2px 3px",
            letterSpacing: "0.24em",
          }}
        >
          Port Harcourt - Aba
        </h2>

        <div className="d-none d-md-block">
          <h3
            className="text-primary m-0"
            style={{
              fontSize: "100px",
              letterSpacing: "7px",
              textShadow: "black 5px 5px 10px",
            }}
          >
            NRC
          </h3>
          <h4
            className="text-primary fs-15"
            style={{
              marginTop: "-20px",
              marginLeft: "1px",
              textShadow: "black 1px 1px 1px",
              letterSpacing: "0.26px",
            }}
          >
            Nigerian Railway Corporation
          </h4>
        </div>
      </div>
    </div>

    <div className="d-block d-lg-none">
      <div>
        <h2
          className={`text-white text-center ${isSmallDevice? "" : "mt-2"}`}
          style={{ fontWeight: "600", fontSize: isSmallDevice? "14px" : "",}}
        >
          Port Harcourt - Aba
        </h2>
        <h5 className="text-primary text-center" style={{ fontSize:isSmallDevice? "12px" : "17px", marginBottom: "4px" }}>
          Welcome To Your Ticketing Platform
        </h5>
      </div>
    </div>
  </Col>
  )
};

export default Heading;
