import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext'; // Adjust the import path as necessary

const CrewHeading: React.FC = () => {
  const globalContext = useContext(GlobalContext) as GlobalContextType;

  if (!globalContext) {
    return null;
  }

  const { user } = globalContext;
  console.log('User in CrewHeading:', user);

  return (
    <Col md={12} lg={6}>
      <div className="d-none d-md-block w-100 m-0">
        <div className="text-center">
          <h1 className="text-primary" style={{ letterSpacing: '5px', fontSize: '45px', fontWeight: '400' }}>
            {user?.title} {user?.firstName} {user?.lastName}
            <br />
            Is At Your Service
          </h1>

          <h2 className="text-white mt-5 mb-4" style={{ fontSize: '35px', fontWeight: '700' }}>
            ID: {user?.id}
          </h2>

          <div className="d-none d-md-block">
            <h3 className="text-primary m-0" style={{ fontSize: '120px', letterSpacing: '7px' }}>
              NRC
            </h3>
            <h4 className="text-primary fs-18" style={{ marginTop: '-20px', marginLeft: '-5px' }}>
              Nigerian Railway Corporation
            </h4>
          </div>
        </div>
      </div>

      <div className="d-block d-md-none">
        <div>
          <h2 className="text-white text-center">ID Number: {user?.id}</h2>
          <h5 className="text-primary text-center">Welcome To Your Ticketing Platform</h5>
        </div>
      </div>
    </Col>
  );
};

export default CrewHeading;
