import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import Layout from '../../Layout';
import GoButton from '../../component/GoButton/GoButton';
import { postLogin } from '../../helpers/backend_helper';

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const someRoutes = [{ path: '/login' }];
  const matches = matchRoutes(someRoutes, currentLocation);
  const isLargeScreenBelow = useMediaQuery({ query: '(min-width: 992px)' });

  const globalContext = useContext(GlobalContext) as GlobalContextType;
  
  // Initialize state variables
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setErrorState] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Always call hooks in the same order
  const {
    // showSignin,
    setShowSignin,
    updateUser,
    setLoginSuccessfully,
  } = globalContext || {};

  useEffect(() => {
    if (userEmail.trim() !== '' && userPassword.trim() !== '') {
      setErrorState(false);
      setErrorMessage('');
    }
  }, [userEmail, userPassword]);

  useEffect(() => {

  }, [])

  const closeSignin = () => {
    navigate("/");
    // if (setShowSignin) setShowSignin(false);
  };

  const handleSend = async () => {
    if (userEmail.trim() === '' || userPassword.trim() === '') {
      setErrorState(true);
      setErrorMessage('Email and password are required');
    } else {
      try {
        setLoading(true);
        const loginData = { email: userEmail, password: userPassword };
        const result = await postLogin(loginData);
        if (updateUser) updateUser(result); // Update the context with the logged-in user
        if (setLoginSuccessfully) setLoginSuccessfully(true);
        if (setShowSignin) setShowSignin(false);
        setLoading(false);
        navigate('/dashboard'); // Redirect to a dashboard or appropriate page after successful login
      } catch (error) {
        setErrorState(true);
        setLoading(false);
        if (error instanceof Error) {
          setErrorMessage(error.message || 'Login failed. Please check your email and password.');
        } else {
          setErrorMessage('An unknown error occurred. Please try again.');
        }
      }
    }
  };

  if (!globalContext) {
    return null;
  }

  return (
    <Layout>
      {isLargeScreenBelow ? (
        <Modal
          contentClassName="bg-dark text-white px-2 px-lg-5 py-4 rounded-4"
          backdropClassName="bg-transparent"
          show={!!matches}
          size="xl"
          centered
          // dialogClassName="modal-green-border"
        >
          <h1 className="text-capitalize text-center text-green">Sign In</h1>
          <button
            onClick={closeSignin}
            className="text-green position-absolute end-0 mx-3 btn btn-link"
          >
            <i className="bi bi-x display-3" />
          </button>
          <Col
            style={{ background: "linear-gradient(#000,hsla(0,0%,100%,.091))" }}
            md={9}
            lg={12}
            xl={9}
            className="m-auto border border-primary rounded-4 p-2 px-md-3 py-md-3"
          >
            <Modal.Body>
              <h5 className="text-green">Please type in your email and password to sign in.</h5>
              <Form.Group className="mb-3 text-center">
                <Form.Label className="text-green w-100">Please type your email.</Form.Label>
                <Form.Control
                  id="input1"
                  type="email"
                  value={userEmail}
                  placeholder="example@gmail.com"
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="bg-black text-white mx-auto w-75"
                />
                {error && userEmail === '' && (
                  <Form.Text className="text-danger">This field is required</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb3 text-center">
                <Form.Label className="text-green w-100">Please type in your password</Form.Label>
                <Form.Control
                  id="input2"
                  type="password"
                  value={userPassword}
                  placeholder="*************"
                  onChange={(e) => setUserPassword(e.target.value)}
                  className="bg-black text-white mx-auto w-75"
                />
                {error && userPassword === '' && (
                  <Form.Text className="text-danger">This field is required</Form.Text>
                )}
              </Form.Group>
              {errorMessage && (
                <div className="text-danger text-center mb-3">
                  {errorMessage}
                </div>
              )}
              <div className="mb-3 text-center">
                <p>Please click "GO" to continue</p>
                <GoButton disabled={userEmail.trim() === '' || userPassword.trim() === ''} isLoading={loading} onClick={handleSend} />
              </div>
              <div className="text-center">
                <a href="/register" className="text-green">Please click here if you are not a registered user yet.</a>
                <br />
                <a href="/forgot-password" className="text-green">Please click here if you have forgotten your password.</a>
              </div>
            </Modal.Body>
          </Col>
        </Modal>
      ) : (
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <h1 className="text-capitalize text-center text-green">Sign In</h1>
              <h5 className="text-green text-center">Please type in your email and password to sign in.</h5>
              <Form.Group className="mb-3 text-center">
                <Form.Label className="text-green w-100">Please type your email.</Form.Label>
                <Form.Control
                  id="input1"
                  type="email"
                  value={userEmail}
                  placeholder="example@gmail.com"
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="bg-black text-white mx-auto w-75"
                />
                {error && userEmail === '' && (
                  <Form.Text className="text-danger">This field is required</Form.Text>
                )}
              </Form.Group>
              <Form.Group className="mb-3 text-center">
                <Form.Label className="text-green w-100">Please type in your password</Form.Label>
                <Form.Control
                  id="input2"
                  type="password"
                  value={userPassword}
                  placeholder="*************"
                  onChange={(e) => setUserPassword(e.target.value)}
                  className="bg-black text-white mx-auto w-75"
                />
                {error && userPassword === '' && (
                  <Form.Text className="text-danger">This field is required</Form.Text>
                )}
              </Form.Group>
              {errorMessage && (
                <div className="text-danger text-center mb-3">
                  {errorMessage}
                </div>
              )}
              <div className="mb-3 text-center">
                <p>Please click "GO" to continue</p>
                <GoButton disabled={userEmail.trim() === '' || userPassword.trim() === ''} isLoading={loading} onClick={handleSend} />
              </div>
              <div className="text-center">
                <a href="/register" className="text-green">Please click here if you are not a registered user yet.</a>
                <br />
                <a href="/forgot-password" className="text-green">Please click here if you have forgotten your password.</a>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </Layout>
  );
};

export default SignIn;
