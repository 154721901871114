import { APIClient } from "./api_helper";
import { LoginDto, IUserDto, RegisterDto, ChangePwdDto, ForgetPwdDto } from "./models/auth.model";
import * as url from "./url_helper";
import { AxiosResponse } from "axios";

const api = new APIClient();

export const getLoggedInUser = (): IUserDto | null => {
  const user = sessionStorage.getItem("authUser");
  return user ? JSON.parse(user) as IUserDto : null;
};

export const isUserAuthenticated = (): boolean => {
  return getLoggedInUser() !== null;
};

export const postRegister = (data: RegisterDto): Promise<IUserDto> => {
  return api.create(url.POST_REGISTER, data).then((response: AxiosResponse<IUserDto>) => response.data);
};

export const postLogin = async (data: LoginDto): Promise<IUserDto> => {
  try {
    const response: any = await api.create(url.POST_LOGIN, data);
    console.log('Full response:', response); // Log the full response for debugging

    if (response) {
      console.log('Response data:', response);
      console.log('Keys in response:', Object.keys(response));

      if (response.requestSuccessful) {
        const responseData = response.responseData;
        console.log('Response data.responseData:', responseData);

        if (responseData) {
          const { profile, accessToken, roles } = responseData;
          console.log('Profile:', profile);
          console.log('Access Token:', accessToken);
          console.log('Roles:', roles);

          const user = { ...profile, accessToken, roles };
          sessionStorage.setItem('authUser', JSON.stringify(user));
          return user;
        } else {
          console.error('No responseData found in response');
          throw new Error('No responseData found in response');
        }
      } else {
        console.error('Login failed:', response.message);
        throw new Error(response.message || 'Login failed');
      }
    } else {
      console.error('No response data found');
      throw new Error('No response data found');
    }
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
};


export const postForgetPwd = (data: ForgetPwdDto): Promise<any> => {
  return api.create(url.POST_PASSWORD_FORGET, data);
};

export const postChangePwd = (data: ChangePwdDto): Promise<any> => {
  return api.create(url.POST_PASSWORD_CHANGE, data);
};

export const postJwtProfile = (data: IUserDto): Promise<IUserDto> => {
  return api.create(url.POST_EDIT_PROFILE, data).then((response: AxiosResponse<IUserDto>) => response.data);
};

export const postProfile = (data: IUserDto & { idx: string }): Promise<IUserDto> => {
  return api.update(`${url.POST_EDIT_PROFILE}/${data.idx}`, data).then((response: AxiosResponse<IUserDto>) => response.data);
};

export const getUserById = async (userId: string): Promise<IUserDto> => {
  return api.get(`/api/Accounts/${userId}`).then((response: AxiosResponse<IUserDto>) => response.data);
};

export const updateUserPassword = async (userId: string, password: string): Promise<any> => {
  return api.create("/api/Accounts/UpdatePassword", { userId, password });
};

export const getAllUsers = async (): Promise<IUserDto[]> => {
  return api.get("/api/Accounts").then((response: AxiosResponse<IUserDto[]>) => response.data);
};
