//REGISTER
export const POST_REGISTER = "/api/User";

//LOGIN
export const POST_LOGIN = "/api/Accounts";
export const POST_PASSWORD_FORGET = "/api/Accounts/ForgotPassword";
export const POST_PASSWORD_CHANGE = "/api/Accounts/ChangePassword";

//PROFILE
export const POST_EDIT_PROFILE = "/post-jwt-profile";
export const GET_PROFILE = "/user";
