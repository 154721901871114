import React, { createContext, useState, useEffect, ReactNode } from "react";
import { IBuyerDetailsDto, IUserDto } from "../helpers/models/auth.model";
import {
  ILastPurchaseResponse,
  IPurchasedTicket,
  IShoppingBasketData,
} from "../helpers/models/txticket_api.model";
import ApiSessionInstance from "../helpers/session_api";

export interface GlobalContextType {
  user: IUserDto | undefined;
  updateUser: (data: IUserDto) => void;
  resetUser: () => void;
  userRole: string | undefined;
  setUserRole: React.Dispatch<React.SetStateAction<string | undefined>>;
  isSuperAdmin: boolean | undefined;
  setIsSuperAdmin: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  firstName: string | undefined;
  setFirstName: React.Dispatch<React.SetStateAction<string | undefined>>;
  shoppingCartBasket: IShoppingBasketData[];
  totalShoppingCartBasketPrice: number;
  showPay: boolean;
  setShowPay: React.Dispatch<React.SetStateAction<boolean>>;
  ticketList: IPurchasedTicket[];
  setTicketList: (
    ticketList: ILastPurchaseResponse[],
    isAuthenticated?: boolean
  ) => void;
  purchasedTickets: IPurchasedTicket[];
  setPurchasedTickets: (ticketList: IPurchasedTicket[]) => void;
  lastPurchaseTicketList: ILastPurchaseResponse | undefined;
  setLastPurchaseTicketList: (ticketList: ILastPurchaseResponse) => void;
  removeFromShoppingCartBasket: (biunique: number) => void;
  updateShoppingCartBasket: (shoppingData: IShoppingBasketData[]) => void;
  addToShoppingCartBasket: (shoppingData: IShoppingBasketData) => void;
  buyerDetails: IBuyerDetailsDto | undefined;
  updateBuyerDetails: (data: IBuyerDetailsDto) => void;
  resetBuyerDetails: () => void;
  setTotalShoppingCartBasketPrice: React.Dispatch<React.SetStateAction<number>>;
  showSignin: boolean;
  setShowSignin: React.Dispatch<React.SetStateAction<boolean>>;
  loginSuccessfully: boolean;
  setLoginSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
  registerUserData: typeof initialRegisterUserData;
  setRegisterUserData: React.Dispatch<
    React.SetStateAction<typeof initialRegisterUserData>
  >;
  showRegister: boolean;
  setShowRegister: React.Dispatch<React.SetStateAction<boolean>>;
  showForgotpwd: boolean;
  setShowForgotpwd: React.Dispatch<React.SetStateAction<boolean>>;
  showChangePwd: boolean;
  setShowChangePwd: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialRegisterUserData = {
  title: "",
  firstName: "",
  lastName: "",
  no: "",
  city: "",
  road: "",
  state: "",
  country: "",
  telephone: "",
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
};

export const GlobalContext = createContext<GlobalContextType | null>(null);

const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<IUserDto | undefined>(() => {
    const storedUser = sessionStorage.getItem("authUser");
    return storedUser ? JSON.parse(storedUser) : undefined;
  });
  const [userRole, setUserRole] = useState<string | undefined>();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | undefined>();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [buyerDetails, setBuyerDetails] = useState<IBuyerDetailsDto>();
  const [shoppingCartBasket, setShoppingCartBasket] = useState<
    IShoppingBasketData[]
  >([]);
  const [ticketList, setStateTicketList] = useState<IPurchasedTicket[]>([]);
  const [purchasedTickets, setStatePurchasedTicket] = useState<
    IPurchasedTicket[]
  >([]);
  const [lastPurchaseTicketList, setStateLastPurchaseTicketList] =
    useState<ILastPurchaseResponse>();
  const [totalShoppingCartBasketPrice, setTotalShoppingCartBasketPrice] =
    useState<number>(0);
  const [showPay, setShowPay] = useState<boolean>(false);
  const [showForgotpwd, setShowForgotpwd] = useState<boolean>(true);
  const [showSignin, setShowSignin] = useState<boolean>(true);
  const [showChangePwd, setShowChangePwd] = useState<boolean>(true);
  // const [showNin, setShowNin] = useState<boolean>(false);
  const [showRegister, setShowRegister] = useState<boolean>(true);
  const [loginSuccessfully, setLoginSuccessfully] = useState<boolean>(false);
  // const [ninDetails, setNinDetails] = useState<string>("");
  // const [forgotPasswordModal, setForgotPasswordModal] =
  //   useState<boolean>(false);
  // const [successNin, setSuccessNin] = useState<boolean>(false);
  // const [failedNin, setFailedNin] = useState<boolean>(false);
  // const [showChangeEmailSuccess, setShowChangeEmailSuccess] =
  //   useState<boolean>(false);
  const [registerUserData, setRegisterUserData] = useState(
    initialRegisterUserData
  );

  const updateShoppingCartBasket = (shoppingData: IShoppingBasketData[]) => {
    setShoppingCartBasket([...shoppingData].reverse());
  };

  const addToShoppingCartBasket = (shoppingData: IShoppingBasketData) => {
    setShoppingCartBasket((prev) => [...prev, shoppingData]);
  };

  const setTicketList = (
    ticketList: ILastPurchaseResponse[],
    isAuthenticated?: boolean
  ) => {
    if (!ticketList.length || !Object.keys(ticketList[0]).length) return;

    if (isAuthenticated) {
      ApiSessionInstance.setTicketList(ticketList);
      setStateTicketList(ticketList.map((tkr) => tkr.ticketlist).flat());
      return;
    }

    const storedTicketList = ApiSessionInstance.getTicketList();
    const isAlreadyStored = (storedTicketList || []).find(
      (tk) => tk.purchaseid === ticketList[0].purchaseid
    );
    if (!isAlreadyStored) {
      const newTicketList = [...(storedTicketList || []), ...ticketList];
      ApiSessionInstance.setTicketList(newTicketList);
      setStateTicketList(newTicketList.map((tkr) => tkr.ticketlist).flat());
    }
  };

  const setPurchasedTickets = (ticketList: IPurchasedTicket[]) => {
    ApiSessionInstance.setPurchasedTickets(ticketList);
    setStatePurchasedTicket(ticketList);
  };

  const setLastPurchaseTicketList = (ticketList: ILastPurchaseResponse) => {
    setStateLastPurchaseTicketList(ticketList);
  };

  const updateBuyerDetails = (data: IBuyerDetailsDto) => {
    setBuyerDetails((prev) => ({ ...prev, ...data }));
    ApiSessionInstance.setBuyerDetails({ ...buyerDetails, ...data });
  };

  const resetBuyerDetails = () => {
    setBuyerDetails(undefined);
  };

  const updateUser = (data: IUserDto) => {
    setUser((prev) => ({ ...prev, ...data }));
    ApiSessionInstance.setUser({ ...user, ...data });
    sessionStorage.setItem("authUser", JSON.stringify({ ...user, ...data }));
  };

  const resetUser = () => {
    setUser(undefined);
    sessionStorage.removeItem("authUser");
  };

  const removeFromShoppingCartBasket = (biunique: number) => {
    const shoppingData = [...shoppingCartBasket];
    const shoppingCart = shoppingData.filter(
      (shoppingDatum: IShoppingBasketData) =>
        shoppingDatum.biunique !== biunique
    );
    setShoppingCartBasket([...shoppingCart]);
  };

  useEffect(() => {
    const apiBuyerDetails = ApiSessionInstance.getBuyerDetails();
    if (apiBuyerDetails) setBuyerDetails(apiBuyerDetails);
    const apiTicketList = ApiSessionInstance.getTicketList();
    if (apiTicketList)
      setStateTicketList(apiTicketList.map((tkr) => tkr.ticketlist).flat());
    const apiPurchasedTickets = ApiSessionInstance.getPurchasedTickets();
    if (apiPurchasedTickets) setStatePurchasedTicket(apiPurchasedTickets);
    const apiUser = ApiSessionInstance.getUser();
    if (apiUser) setUser(apiUser);
    const storedUser = sessionStorage.getItem("authUser");
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      console.log("Stored User Data:", userData);
      setUser(userData);
    }
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        shoppingCartBasket,
        addToShoppingCartBasket,
        removeFromShoppingCartBasket,
        updateShoppingCartBasket,
        showChangePwd,
        setShowChangePwd,
        showPay,
        setShowPay,
        totalShoppingCartBasketPrice,
        setTotalShoppingCartBasketPrice,
        ticketList,
        showSignin,
        setShowSignin,
        loginSuccessfully,
        setLoginSuccessfully,
        setTicketList,
        showForgotpwd,
        setShowForgotpwd,
        purchasedTickets,
        setPurchasedTickets,
        lastPurchaseTicketList,
        setLastPurchaseTicketList,
        buyerDetails,
        resetBuyerDetails,
        updateBuyerDetails,
        user,
        updateUser,
        resetUser,
        registerUserData,
        setRegisterUserData,
        showRegister,
        setShowRegister,
        userRole,
        setUserRole,
        isSuperAdmin,
        setIsSuperAdmin,
        firstName,
        setFirstName,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
