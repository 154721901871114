import React, { useRef, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";

//SimpleBar
import SimpleBar from "simplebar-react";
import { IRouteConnection } from "../../../helpers/models/route.model";
import moment from "moment";
import {
  ITicketOrder,
  ITicketPrice,
} from "../../../helpers/models/txticket_api.model";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

interface ISelectTicketDropdownProps {
  tickets: IRouteConnection[];
  ticket?: IRouteConnection;
  ticketPrice?: ITicketPrice;
  hasConfirmedBooking: boolean;
  passengers: number;
  handleTicketPriceSelection: (selected: ITicketPrice) => void;
  handleTicketSelection: (selected: IRouteConnection) => void;
  handleTicketsOrder: React.Dispatch<React.SetStateAction<ITicketOrder[]>>;
  text: string;
  ticketPrices:
    | undefined
    | {
        [key: string]: ITicketPrice[];
      };
}
//tod_arr - tod_dep // "15:05:00"

const SelectTicketDropdown = ({
  text,
  tickets,
  ticket,
  ticketPrice,
  handleTicketSelection,
  handleTicketPriceSelection,
  handleTicketsOrder,
  ticketPrices,
  passengers,
  hasConfirmedBooking,
}: ISelectTicketDropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState<IRouteConnection>(
    ticket as IRouteConnection
  );
  const [priceState, setPriceState] = useState<ITicketPrice>(
    ticketPrice as ITicketPrice
  );
  const reservationClass: { [key: string]: string } = {
    1: "First",
    2: "Second",
  };
  const [focus, setFocus] = useState(false);

  const timeConvert = (time: string) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(parseInt(seconds, 10));
    return date;
  };

  const timeTaken = (dept: string, arriv: string) => {
    const deptTime = timeConvert(dept);
    const arrivTime = timeConvert(arriv);
    // Calculate the difference in milliseconds
    const differenceMs = arrivTime.getTime() - deptTime.getTime();

    // Convert the difference to hours, minutes, and seconds
    const differenceDate = new Date(differenceMs);
    const hoursDiff = differenceDate.getUTCHours();
    const minutesDiffs = differenceDate.getUTCMinutes();

    let hours, minutes;

    if (hoursDiff < 10) {
      hours = "0" + hoursDiff;
    } else {
      hours = hoursDiff;
    }

    if (minutesDiffs < 10) {
      minutes = "0" + minutesDiffs;
    } else {
      minutes = minutesDiffs;
    }

    return hours + ":" + minutes;
  };

  const filterSelected = () => {
    // return tickets.filter(
    //   (eachTicket: IRouteConnection) =>
    //     eachTicket.wait_time_s !== ticket?.wait_time_s
    // );
    return tickets;
  };

  const getValue = () => {
    return ticket?.wait_time_s;
  };

  const getTicketPricing = (key: number, ticketClass: "first" | "second") => {
    const ticketClassIndex = { first: 0, second: 1 };
    const index = ticketClassIndex[ticketClass];
    if (ticketPrices) return ticketPrices[`${key}`][index];
    toast.error("Price is not available for this selected ticket");
  };

  const getTicketPrice = (key: number, ticketClass: "first" | "second") => {
    const ticketClassIndex = { first: 0, second: 1 };
    const index = ticketClassIndex[ticketClass];
    const kobo = 100;
    if (ticketPrices) return ticketPrices[`${key}`][index].centprice / kobo;
    return 0;
  };

  const onClick = () => {
    const timeAndClass =
      document.querySelector<HTMLButtonElement>("#timeAndClass");
    if (timeAndClass) timeAndClass.click();
    setFocus(true);
  };

  const onSelect = (
    selected: IRouteConnection,
    ticketClass: "first" | "second"
  ) => {
    setState(selected);
    handleTicketSelection(selected);
    const price = getTicketPricing(
      selected.wait_time_s,
      ticketClass
    ) as ITicketPrice;
    setPriceState(price);
    handleTicketPriceSelection(price);
    handleTicketsOrder((prev) => {
      const newState = [
        ...prev,
        {
          prid: price.prid,
          pvid: price.pvid,
          route: selected,
          centprice: price.centprice,
          id: price.prid,
        },
      ];
      return newState;
    });
    if (ref.current) ref.current.click();
    setFocus(false);
  };

  // const renderTooltip = (props: any) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     {/* Click the price */}
  //   </Tooltip>
  // );

  const getTicketDropdownPlaceholderText = () => {
    if (!tickets.length) {
      return "loading station...";
    } else if (state) {
      const headCount = hasConfirmedBooking ? `${passengers} x ` : "";
      const reservationClassText =
        reservationClass[String(priceState?.reservation_class)];
      // const withClassText = hasConfirmedBooking ? "" : "Class";
      const withClassText = hasConfirmedBooking ? "Class" : "Class";

      return `${headCount}${reservationClassText} ${withClassText} 
       ${moment(state.leg[0].tod_dep, "HH:mm:ss").format("hh:mmA")} -> ${moment(
        state.leg[0].tod_arr,
        "HH:mm:ss"
      ).format("hh:mmA")}`; // "1 x Second Class 06:10 PM -> 06:15 PM"
    }
    return text;
  };
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 330px)",
  });
  const timeClass = !state ? "text-primary" : "text-white";
  const timeClassBorder = state ? "border-none" : "timeClassBorder";

  const isMediumScreen = useMediaQuery({
    query: "(min-width: 768px)",
  });
  // const smm = {
  //   header: {
  //     fontSize: "10px",
  //   },
  // };

  // const padTop = isMediumScreen ? "" : "8px";

  const timeStyle = isMediumScreen ? "fs-14" : isSmallScreen ? "fs-9" : "fs-9";
  const headingStyle = isMediumScreen ? "" : isSmallScreen ? "fs-10" : "fs-12";

  return (
    <React.Fragment>
      <h6
        className={`${timeClass} ${
          isSmallScreen ? "fs-14" : "fs-19"
        } text-uppercase `}
        style={{
          letterSpacing: "2.1px",
          fontWeight: 700,
          marginTop: isSmallScreen ? "-10px !important" : "",
          marginBottom: 0,
        }}
        onClick={onClick}
      >
        Time and Class
      </h6>
      <Dropdown
        className={`${timeClassBorder} dropdown topbar-head-dropdown ${
          isSmallScreen ? "mb-1" : "mb-2"
        } `}
        id="timeAndClassDropdown"
        ref={ref}
        autoClose={"outside"}
        onClick={onClick}
        // style={{ border: "2px solid white", borderRadius:"5px"}}
      >
        <Dropdown.Toggle
          id="timeAndClass"
          type="button"
          // className={`fs-14 btn-lg btn btn-topbar btn-ghost-dark arrow-none overflow-hidden ${
          className={`btn-sm btn btn-topbar btn-ghost-dark arrow-none overflow-hidden  ${
            getValue()
              ? "bg-dark fs-14 "
              : isSmallScreen
              ? "bg-primary fs-10"
              : ` bg-primary fs-18`
          } w-100  ${state ? "bg-transparent" : "border border-primary"} ${
            state ? "p-0" : ""
          }`}
          style={{
            fontWeight: state ? 500 : 600,
            // padding: 0,
            letterSpacing: "1.2px",
            padding: "10px",
            margin: "0",
            // whiteSpace: "pre-wrap",
            marginTop: state ? "-8px" : "0",
            color: focus ? "white" : state ? "white " : "black",
            marginBottom: isMediumScreen ? "-5px" : "",
          }}
        >
          {getTicketDropdownPlaceholderText()}
        </Dropdown.Toggle>
        <Dropdown.Menu
          variant="primary"
          className="dropdown-menu-xl dropdown-menu-xs dropdown-menu-end p-0 table-adjuster"
          aria-labelledby="page-header-notifications-dropdown"
        >
          <div
            className="dropdown-head rounded-top text-white bg-dark rounded-2"
            style={{ border: "1px solid #00f600" }}
          >
            {/* <div className="bg-primary bg-pattern">
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0"></Table>
              </div>
            </div> */}
            <div className="pt-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="">
                {/* <Table className="align-middle table-nowrap mb-0 "> */}

                <table
                  style={{
                    width: "100%",
                    margin: "auto",
                    textAlign: "center",
                    border: "none",
                  }}
                >
                  <thead style={{ marginBottom: "10px" }}>
                    <tr>
                      <th
                        className={`${headingStyle} bg-dark text-primary border-0`}
                        style={{ width: "20%", border: "1px solid green" }}
                      >
                        DPRT.
                      </th>
                      <th
                        className={`${headingStyle} bg-dark text-primary border-0`}
                        style={{ width: "20%", border: "1px solid green" }}
                      >
                        DUR.
                      </th>
                      <th
                        className={`${headingStyle} bg-dark text-primary border-0`}
                        style={{ width: "20%", border: "1px solid green" }}
                      >
                        ARR
                      </th>
                      <th
                        className={`${headingStyle} bg-dark text-primary border-0`}
                        style={{ width: "20%", border: "1px solid green" }}
                      >
                        SECOND
                      </th>
                      <th
                        className={`${headingStyle} bg-dark text-primary border-0`}
                        style={{ width: "20%", border: "1px solid green" }}
                      >
                        FIRST
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterSelected().map((eachTicket) => (
                      <tr key={eachTicket.wait_time_s}>
                        <td
                          className={`${timeStyle} bg-transparent text-white border-0`}
                          style={{ fontWeight: 400, width: "20%" }}
                        >
                          {moment(eachTicket.leg[0].tod_dep, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </td>
                        <td
                          className={`${timeStyle} bg-transparent text-white border-0`}
                          style={{ fontWeight: 400, width: "20%" }}
                        >
                          {timeTaken(
                            eachTicket.leg[0].tod_dep,
                            eachTicket.leg[0].tod_arr
                          )}
                        </td>
                        <td
                          className={`${timeStyle} bg-transparent text-white border-0`}
                          style={{ fontWeight: 400, width: "20%" }}
                        >
                          {moment(eachTicket.leg[0].tod_arr, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </td>
                        <td
                          className={`${timeStyle} bg-transparent text-dark p-0 border-0`}
                          style={{ width: "20%" }}
                        >
                          <Button
                            onClick={() => onSelect(eachTicket, "second")}
                            size="sm"
                            className={`${timeStyle} dropdown-button`}
                            style={{
                              background: "#76D6FA",
                              width: isSmallScreen ? "42px" : "65px",
                              marginRight: isMediumScreen ? "12px" : "",
                              color: "black",
                              fontWeight: 600,
                              marginLeft: isMediumScreen ? "" : "10px",
                            }}
                          >
                            ₦
                            {getTicketPrice(
                              eachTicket.wait_time_s,
                              "second"
                            ).toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                          </Button>
                        </td>
                        <td
                          className={`${timeStyle} bg-transparent text-dark p-0 border-0`}
                          style={{ width: "20%" }}
                        >
                          <Button
                            onClick={() => onSelect(eachTicket, "first")}
                            size="sm"
                            className={`${timeStyle} dropdown-button`}
                            style={{
                              background: "#eb58ed",
                              marginRight: "5px",
                              color: "black",
                              fontWeight: 600,
                              marginLeft: isMediumScreen ? "" : "2px",
                            }}
                          >
                            ₦
                            {getTicketPrice(
                              eachTicket.wait_time_s,
                              "first"
                            ).toLocaleString(navigator.language, {
                              minimumFractionDigits: 0,
                            })}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </SimpleBar>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment>
  );
};

export default SelectTicketDropdown;
