import axios, { AxiosResponse, AxiosError } from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

interface AuthUser {
  tokens: {
    accessToken: string;
  };
}

let token: string | null = null;

const authUser = sessionStorage.getItem("authUser");
if (authUser) {
  try {
    const parsedAuthUser: AuthUser = JSON.parse(authUser);
    token = parsedAuthUser.tokens?.accessToken || null;
  } catch (error) {
    console.error("Failed to parse authUser from sessionStorage:", error);
  }
}

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response.data ? response.data : response;
  },
  (error: AxiosError) => {
    let message: string;
    switch (error.response?.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || "An unknown error occurred";
    }
    return Promise.reject(message);
  }
);

const setAuthorization = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

class APIClient {
  get = (url: string, params?: Record<string, any>) => {
    let response;
    let paramKeys: string[] = [];

    if (params) {
      Object.keys(params).forEach((key) => {
        paramKeys.push(`${key}=${params[key]}`);
      });

      const queryString = paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`);
    } else {
      response = axios.get(url);
    }

    return response;
  };

  create = (url: string, data: any) => {
    return axios.post(url, data);
  };

  update = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  put = (url: string, data: any) => {
    return axios.put(url, data);
  };

  delete = (url: string, config?: any) => {
    return axios.delete(url, { ...config });
  };
}

const getLoggedInUser = (): AuthUser | null => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    try {
      return JSON.parse(user) as AuthUser;
    } catch (error) {
      console.error("Failed to parse authUser from sessionStorage:", error);
      return null;
    }
  }
};

export { APIClient, setAuthorization, getLoggedInUser };
